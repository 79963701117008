import * as React from 'react';
import s from './WishlistProductItem.scss';
import {RemoveIcon} from '../Icons/RemoveIcon';

import {
  ProductItem,
  IProductItemProps,
} from '@wix/wixstores-client-gallery/dist/es/src/common/components/ProductItem/ProductItem';
import {withGlobalProps, IProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

type WishlistProductItemProps = IProductItemProps & IProvidedGlobalProps;

const Remove: React.FunctionComponent<{handleClick: any}> = ({handleClick}) => {
  return (
    <a data-hook="remove-product" onClick={handleClick}>
      <RemoveIcon className={s.removeIcon} />
    </a>
  );
};

const WishlistProductItemComponent = (props: WishlistProductItemProps & IProvidedTranslationProps) => {
  const {
    t,
    product,
    globals,
    globals: {removeProduct},
  } = props;

  return (
    <div className={s.container}>
      <Remove handleClick={() => removeProduct(product.id)} />
      <ProductItem {...props} {...globals} t={t} />
    </div>
  );
};

export const WishlistProductItem = withGlobalProps(withTranslations()(WishlistProductItemComponent));
