import * as React from 'react';
import styles from './EmptyState.scss';
import classnames from 'classnames';

export enum DataHooks {
  Message = 'empty-state-message',
  Link = 'empty-state-link',
}

export interface EmptyStateProps {
  texts: {
    noProductsMessage: string;
    link: string;
  };
  dataHook: string;
  isMobile: boolean;
  handleClick(): void;
  linkUrl: string;
}

export class EmptyState extends React.Component<EmptyStateProps> {
  private readonly onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.handleClick();
  };

  public render() {
    const {texts, isMobile, linkUrl} = this.props;

    return (
      <section className={classnames(styles.emptyState, {[styles.mobile]: isMobile})} data-hook={this.props.dataHook}>
        <h2 data-hook={DataHooks.Message} className={styles.header}>
          {texts.noProductsMessage}
        </h2>
        <a href={linkUrl} className={styles.link} data-hook={DataHooks.Link} onClick={this.onClick}>
          {texts.link}
        </a>
      </section>
    );
  }
}
